.offerForm {
  .offerItemsHeaderRow {
    display: none;
    @include media(tablet up) {
      display: flex;
    }
  }

  .fieldTitle {
    margin-left: 12px;
    margin-bottom: 16px;

    @include media(tablet up) {
      display: none;
    }
  }

  .offerItems {
    flex-direction: column;

    @include media(tablet up) {
      flex-direction: row;
    }

    .ant-col {
      @include media(tablet down) {
        max-width: 100% !important;
      }
    }

    .ant-form-item-label {
      @include media(tablet up) {
        display: none;
      }
    }
  }
}
