.login {
    max-width: 360px;

    .main-title {
        font-size: 32px;
    }
}

.login-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
