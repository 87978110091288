.campaignStats {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 1px lightgray;
  height: 320px;
  padding: 24px;

  @include media(mobile down) {
    height: 500px;
  }

  .ant-typography {
    margin: 0 0 0 0 !important;
  }
  
  .customLegend {
    display: grid;
    grid-template-columns: auto auto;
    gap: 16px;
  }
  
  .legendItem {
    min-width: 155px;
    display: inline-block;
  }

  .legendItemText {
    font-weight: bold;
    padding-left: 24px;
    min-width: 155px;
    display: inline-block;
  }
  
  .recharts-legend-wrapper {
    width: 50% !important;

    @include media(mobile down) {
      width: 100% !important;
    }
  }
}
