.ant-list-item-meta-title {
  margin: 0;
}

.ant-list-item:hover {
  box-shadow: 0 0 4px #eee;
  background-color: $color-quaternary;
}

.notificationPopover {
  .ant-popover {
    box-shadow: 0 0 4px $color-quaternary;
  }
}
