:root {
  /* button */
  --fc-button-bg-color: #ffffff;
  --fc-button-border-color: #D9D9D9;
  --fc-button-text-color: #000000;

  /* button hover */
  --fc-button-hover-bg-color: #ffffff;
  --fc-button-hover-border-color: #3f69ff;

  /* button active */
  --fc-button-active-bg-color: #ffffff;
  --fc-button-active-border-color: #3f69ff;

  --fc-today-bg-color: #ffffff;
}

.fc-theme-standard .fc-scrollgrid,
.fc-theme-standard td,
.fc-theme-standard th {
  border: none;
}

.fc .fc-daygrid-day-frame {
  box-shadow: 0px 2px 0px 0px #F0F0F0 inset;
  margin-inline: 4px;
}

.fc .fc-daygrid-day-top {
  flex-direction: row;
}

.fc th {
  text-align: start;
}

.fc .fc-prev-button,
.fc .fc-next-button {
  border: 0;
  padding-inline: 0;
  margin: 0!important;
}

// .fc .fc-timegrid-slot-minor {
//   border-top: none;
//   border-bottom: 1px solid lightgray;
// }

// .fc .fc-timegrid-axis-cushion,
// .fc .fc-timegrid-slot-label-cushion {
//   color: lightgray;
// }

// .fc .fc-col-header-cell-cushion {
//   color: black;
//   display: flex;
//   flex-direction: row-reverse;
//   justify-items: end;
// }

.fc .fc-toolbar-chunk {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.fc .fc-toolbar {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
}

.fc .fc-toolbar-title {
  font-size: 14px;
  text-transform: capitalize;
}

.fc .fc-col-header-cell-cushion {
  color: #000000;
  text-transform: capitalize;
  cursor: default;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 12px;
}

.fc .fc-daygrid-day-number {
  padding: 4px 8px;
  color: #000000;
  cursor: default;
}

// Timegrid

.fc table {
  border-collapse: separate;
}

.fc .fc-timeGridWeek-view .fc-col-header-cell-cushion {
  font-weight: 400;
  padding: 16px 12px;
}

.fc .fc-timeGridWeek-view th {
  text-align: center;
}

.fc-timeGridWeek-view .fc-timegrid-col {
  padding: 12px;
}

.fc .fc-timegrid-slot {
  border: none;
  box-shadow: 0px 2px 0px 0px #F0F0F0 inset;
}

.fc .fc-timegrid-slot-minor,
.fc .fc-timegrid-slot-label {
  box-shadow: none;
}

.fc .fc-timegrid-now-indicator-line {
  border-width: 2px 0px 0px;
}


// List

.fc .fc-list-day {
  display: none;
}

.fc-theme-standard .fc-list {
  border: 0;
}

.fc-list-event-time,
.fc-list-event-graphic {
  display: none;
}

.fc .fc-list-table td {
  padding: 8px;
}

.fc .fc-list-event-title a {
  color: #3F69FF;
}
