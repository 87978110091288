.monthlyStats {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 1px lightgray;
  height: 320px;
  width: 100%;
  padding: 24px;
  padding-bottom: 0;

  h2{
    margin: 0 0 16px 0;
  }
}
